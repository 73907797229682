var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-form-modal", {
    attrs: { title: "Reload", closable: "", "modal-class": "sm:max-w-3xl" },
    on: {
      submit: _vm.submitForm,
      keypress: function ($event) {
        if (
          !$event.type.indexOf("key") &&
          _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
        )
          return null
        return _vm.submitForm.apply(null, arguments)
      },
      cancel: _vm.closeModal,
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "overflow-visible pb-6 px-3" },
              [
                _c("form-row", {
                  scopedSlots: _vm._u([
                    {
                      key: "left",
                      fn: function () {
                        return [
                          _c(
                            "sun-label-group",
                            { attrs: { text: "Start date" } },
                            [
                              _c("sun-date-picker", {
                                staticClass: "w-full",
                                attrs: {
                                  value: {
                                    startDate: _vm.initialMinDate,
                                    endDate: _vm.initialMinDate,
                                  },
                                  "append-to-body": "",
                                  "max-date": _vm.initialMaxDate,
                                  "min-date": _vm.minDayPeriod,
                                  name: "startDate",
                                  required: "required",
                                  "single-date-picker": "",
                                  "text-error":
                                    "Start date is a required field.",
                                },
                                on: { change: _vm.onNewStartDate },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "right",
                      fn: function () {
                        return [
                          _c(
                            "sun-label-group",
                            { attrs: { text: "End date" } },
                            [
                              _c("sun-date-picker", {
                                staticClass: "w-full",
                                attrs: {
                                  value: { startDate: _vm.initialMaxDate },
                                  "append-to-body": "",
                                  "max-date": _vm.maxDayPeriod,
                                  "min-date": _vm.initialMinDate,
                                  name: "endDate",
                                  required: "required",
                                  "single-date-picker": "",
                                  "text-error": "End date is a required field.",
                                },
                                on: { change: _vm.onNewEndDate },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "flex flex-wrap justify-between sm:px-3" },
              [
                _c("save-button", {
                  staticClass: "w-full text-sm custom-p-1 sm:w-auto",
                  attrs: {
                    id: "reload-submit",
                    loading: _vm.isLoading,
                    variant: "pill",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "text",
                      fn: function () {
                        return [_vm._v(" Reload")]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _c(
                  "sun-button",
                  {
                    staticClass: "w-full text-sm custom-p-1 sm:w-auto",
                    attrs: { variant: "pill", color: "white" },
                    on: { click: _vm.closeModal },
                  },
                  [_vm._v(" Cancel ")]
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }