import { TODAY } from '@/utils/dateTime/today';
import { YESTERDAY } from '@/utils/dateTime/yesterday';
import { addDays } from '@/utils/dateTime/addDays';

const MAX_DAYS_REIMPORT = 30 * 6;

export class PlatformReimport {
  constructor(platformId = null, startDate = null, endDate = null) {
    this.platformId = platformId;
    this.startDate = startDate || this.initialDayByDefault();
    this.endDate = endDate || this.getMaxDayPeriod();
  }

  payload() {
    return this;
  }

  getMinDayPeriod() {
    return addDays(TODAY, -MAX_DAYS_REIMPORT);
  }

  getMaxDayPeriod() {
    return YESTERDAY;
  }

  initialDayByDefault() {
    return new Date(YESTERDAY.getFullYear(), YESTERDAY.getMonth(), 1, 12);
  }
}
