<template>
  <asterix-form-modal
    title="Reload"
    closable
    modal-class="sm:max-w-3xl"
    @submit="submitForm"
    @keypress.enter="submitForm"
    @cancel="closeModal"
  >
    <template #content>
      <div class="overflow-visible pb-6 px-3">
        <form-row>
          <template #left>
            <sun-label-group text="Start date">
              <sun-date-picker
                :value="{ startDate: initialMinDate, endDate: initialMinDate }"
                class="w-full"
                append-to-body
                :max-date="initialMaxDate"
                :min-date="minDayPeriod"
                name="startDate"
                required="required"
                single-date-picker
                text-error="Start date is a required field."
                @change="onNewStartDate"
              />
            </sun-label-group>
          </template>
          <template #right>
            <sun-label-group text="End date">
              <sun-date-picker
                :value="{ startDate: initialMaxDate }"
                class="w-full"
                append-to-body
                :max-date="maxDayPeriod"
                :min-date="initialMinDate"
                name="endDate"
                required="required"
                single-date-picker
                text-error="End date is a required field."
                @change="onNewEndDate"
              />
            </sun-label-group>
          </template>
        </form-row>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-wrap justify-between sm:px-3">
        <save-button id="reload-submit" :loading="isLoading" variant="pill" class="w-full text-sm custom-p-1 sm:w-auto">
          <template #text> Reload</template>
        </save-button>

        <sun-button variant="pill" class="w-full text-sm custom-p-1 sm:w-auto" color="white" @click="closeModal">
          Cancel
        </sun-button>
      </div>
    </template>
  </asterix-form-modal>
</template>

<script>
import { mapActions } from 'vuex';
import AsterixFormModal from '@/components/organisms/shared/AsterixFormModal';
import SaveButton from '@/components/atoms/SaveButton';
import FormRow from '@/components/atoms/FormRow/FormRow';
import { PlatformReimport as PlatformReload } from '@/entities/dashboardSSP/PlatformReimport';
import { stringToDate } from '@/filters/dateFilters';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import { dateWithoutTimeFormat } from '@/utils/dateTime/date';

export default {
  name: 'PlatformReloadModal',
  components: {
    AsterixFormModal,
    SaveButton,
    FormRow,
  },
  mixins: [],
  props: {
    platformId: { type: String, default: () => null },
    reloadFn: { type: Function, default: () => null },
  },
  data: () => ({
    isLoading: false,
    platformReload: null,
    minDayPeriod: null,
    maxDayPeriod: null,
  }),
  computed: {
    initialMaxDate() {
      return this.platformReload.endDate ? stringToDate(this.platformReload.endDate) : this.maxDayPeriod;
    },
    initialMinDate() {
      return this.platformReload.startDate ? stringToDate(this.platformReload.startDate) : this.minDayPeriod;
    },
  },
  created() {
    this.platformReload = new PlatformReload(this.platformId);
    this.minDayPeriod = this.platformReload.getMinDayPeriod();
    this.maxDayPeriod = this.platformReload.getMaxDayPeriod();
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
    }),
    async submitForm({ valid }) {
      if (!valid) return;

      this.isLoading = true;
      try {
        const formattedPlatform = this.platformReload;
        formattedPlatform.startDate = dateWithoutTimeFormat(this.platformReload.startDate);
        formattedPlatform.endDate = dateWithoutTimeFormat(this.platformReload.endDate);
        await this.reloadFn(formattedPlatform);
        this.createToast(Toast.success('Yes! Reloading data...', 'The reload action is running successfully.'));
        this.$emit('reload', this.platformId);
      } catch (error) {
        this.createToast(Toast.error('Oops! Something went wrong', error.message));
      } finally {
        this.isLoading = false;
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit('cancel');
    },
    onNewStartDate({ value }) {
      this.$set(this.platformReload, 'startDate', value.startDate);
    },
    onNewEndDate({ value }) {
      this.$set(this.platformReload, 'endDate', value.endDate);
    },
  },
};
</script>
